.imageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.bg {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.homeContainer {
    flex: 1;
}
.header {
    position: absolute;
    top: 30%;
    width: 40%;
    left: 10%;
}
.title {
    color: black;
    font-size: 5em;
    width: 100%;
    font-weight: 700;
    padding: 0;
}
.linkContainer {
    flex-direction: row;
    padding-top: 1%;
    flex-wrap: wrap;
    text-align: left;
    align-items: center;
    display: flex;
    position: relative;
}
.pageLink {
    position: relative;
    padding-right: 5%;
    color: black;
    font-size: 1em;
    font-weight: 500;
    text-decoration: none;
    
}
.pageLink:hover {
    color: black;
}
.linkdiv {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
}

.pageIcon {
    padding-right: 2%;
}

@media screen and (max-width: 1100px) {
    .bg{
        position: relative;
        object-fit: cover;
        max-height: 100vh;
        max-width: none;
    }
    .header {
        position: absolute;
        top: 20%;
    }
}

@media screen and (max-width: 750px) {
    .bg{
        position: relative;
        object-fit: cover;
        max-height: 100vh;
        max-width: none;
    }
    .header {
        position: absolute;
        top: 20%;
        text-align: center;
    }
    .title {
        color: black;
        font-size: 3em;
        width: 100%;
        font-weight: 700;
        padding: 0;
    }
    .position {
        padding-top: 2%;
        padding-bottom: 2%;
        font-size: 1em;
    }
    .linkdiv {
        flex-direction: row;
        display: flex;
        align-items: center;
        width: 100%;
    }
    .linkContainer {
        justify-content: center;
    }
}